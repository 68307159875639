.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__error {
    color: tomato;
  }
  &__message {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
  }
}