@import 'src/assets/fonts/fonts';

* {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  outline: none;
  box-sizing: border-box;
  position: relative;
}

body {
  background: #1A191E;
}

body, button, input {
  font-family: 'Helvetica Neue', sans-serif;
  color: #1A191E;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Helvetica Inserat', sans-serif;
  span {
    background: linear-gradient(270deg, #D858AE 0%, #4F69C1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

h1 {
  font-size: 70px;
  @media (max-width: 1024px) {
    font-size: 60px;
  }
}

h2 {
  font-size: 50px;
  @media (max-width: 1024px) {
    font-size: 40px;
  }
}

h3 {
  font-size: 30px;
  @media (max-width: 1024px) {
    font-size: 20px;
  }
}

p {
  font-size: 24px;
  line-height: 1.5;
  color: #97979E;
}

.container {
  max-width: 1440px;
  width: 100%;
  @media (max-width: 1440px) {
    padding: 0 24px;
  }
}

header, section, footer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.react-calendar__navigation {
  display: flex;
  padding: 20px 24px;
  border-bottom: 1px solid #E8E8E8;
  button {
    font-family: 'Helvetica Inserat', sans-serif;
  }
  &__arrow {
    font-size: 32px;
    line-height: 1;
    margin: 0 12px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__label {
    font-size: 20px;
  }
}

.react-calendar__viewContainer {
  padding: 24px 12px;
}

.react-calendar__month-view__weekdays {
  &__weekday {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #97979E;
    padding-bottom: 20px;
    abbr {
     text-decoration: none;
    }
  }
}

.react-calendar__month-view__days {
  &__day {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #1A191E;
    padding: 4px 0;
    &--neighboringMonth {
      pointer-events: none;
      opacity: 0;
    }
    abbr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

.react-calendar__tile--active {
  abbr {
    font-weight: 500;
    color: #ffffff;
    background: linear-gradient(270deg, #D858AE 0%, #4F69C1 100%);
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper {
  flex: 1;
}