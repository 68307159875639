.hero {
  min-height: 100vh;
  background: #1A191E;
  &_background {
    position: absolute;
    left: 0;
    top: 0;
  }
  &_full {
    height: 100%;
  }
  @media (max-width: 1024px) {
    padding-top: 156px;
    min-height: auto;
    &_background {
      min-height: 100vh;
    }
  }
  :global(.container) {
    display: flex;
    align-items: center;
    position: static;
    @media (max-width: 1280px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
  &__info {
    @media (max-width: 1280px) {
      margin-bottom: 48px;
    }
    h1 {
      margin-bottom: 16px;
      color: #ffffff;
      max-width: 645px;
    }
    p {
      margin-bottom: 64px;
      max-width: 632px;
    }
  }
  &__socials {
    position: absolute;
    left: 90px;
    bottom: 80px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1280px) {
      position: static;
      flex-direction: row;
      margin-bottom: 24px;
    }
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 1px solid #343543;
      &:not(:last-child) {
        margin-bottom: -12px;
        @media (max-width: 1024px) {
          margin-bottom: 0;
          margin-right: 12px;
        }
      }
    }
  }
  &__image {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    width: 75%;
    height: 80%;
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 100%;
      max-height: 100%;
    }
    @media (max-width: 1024px) {
      position: static;
      width: calc(100% + 48px);
      height: auto;
      margin-right: -24px;
      margin-left: -24px;
      img {
        position: static;
      }
    }
  }
  &__background {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    width: 100%;
    overflow: hidden;
  }
  &::before {
    content: '';
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    left: -195px;
    top: 116px;
    background: linear-gradient(270deg, #D858AE 0%, #4F69C1 100%);
    filter: blur(150px);
  }
}