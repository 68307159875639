.pay {
  padding-top: 144px;
  padding-bottom: 100px;
  :global(.container) {
    > h1 {
      color: #ffffff;
      margin-bottom: 56px;
    }
  }
  &__block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-shadow: 0px 80px 100px rgba(26, 25, 30, 0.05);
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
    }
    @media (max-width: 768px) {
      width: calc(100% + 48px);
      left: -24px;
    }
  }
}

.payments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 80px 60px 80px;
  border-right: 2px solid #E8E8E8;
  @media (max-width: 1024px) {
    border-right: 0;
    border-bottom: 2px solid #E8E8E8;
  }
  @media (max-width: 768px) {
    padding: 24px;
  }
  &__info {
    &-total {
      display: flex;
      flex-direction: column;
      margin-bottom: 54px;
      span {
        font-size: 24px;
        color: #97979E;
        margin-bottom: 24px;
      }
    }
  }
  &__items {
    margin-bottom: 96px;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &:not(:last-child) {
        margin-bottom: 28px;
      }
      span {
        font-size: 24px;
        font-weight: 500;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
      &__title {
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
          margin-right: 24px;
        }
        &-info {
          font-weight: 400!important;
          color: #97979E;
          margin-bottom: 8px;
        }
      }
      &_total {
        border-top: 2px solid #E0E0E0;
        padding-top: 28px;
      }
    }
  }
  &__links {
    display: flex;
    list-style-type: none;
    li {
      &:not(:last-child) {
        margin-right: 48px;
        &::after {
          content: '';
          position: absolute;
          width: 2px;
          height: 24px;
          background: #97979E;
          right: -26px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      a {
        text-decoration: none;
        font-size: 24px;
        color: #97979E;
        @media (max-width: 1280px) {
          font-size: 18px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 60px 64px;
  gap: 36px;
  @media (max-width: 768px) {
    padding: 24px;
  }
  &__or {
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      color: #97979E;
      margin: 0 16px;
      white-space: nowrap;
    }
    &::before {
      content: '';
      width: 50%;
      height: 2px;
      background: #E0E0E0;
    }
    &::after {
      content: '';
      width: 50%;
      height: 2px;
      background: #E0E0E0;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &-row {
      display: flex;
      align-items: flex-end;
      gap: 16px;
    }
    &-button {
      margin-top: 16px;
    }
  }
}